<template>
    <div class="post">
        <div class="feed-post-header">
            <div class="feed-post-user-logo">
                <u-avatar
                    :user="post.user"
                    :size="4"
                ></u-avatar>
            </div>

            <div class="post-info" style="display: flex; flex-direction: column">
                <p
                    class="post-user-name"
                    v-html="post.user.name"
                ></p>

                <div class="box-info">
                    <p
                        class="post-date"
                        v-html="postDate(post.createdAt)"
                    ></p>

                    <u-text v-if="post.wasEdited" size="4" color="grey4" class="edited-tag">Editado</u-text>

                    <p
                        v-if="post.model && post.model.type == 'projects'"
                        class="post-project"
                    ><i class="fas fa-angle-right"></i> {{post.model.name}} </p>
                </div>
            </div>
        </div>

        <p 
            v-if="!post.postFormResponse"
            :id="'description-' + post.id"
            :class="['feed-post-description', {'is-activated' : seeActivated}, {'fit' : checkSize}]"
            v-html="replaceDescription(postDescription)"
        ></p>

        <p 
            v-if="checkSize"
            class="see-more" 
            @click="seeMoreDescription()" 
            v-html="isActivated"
        ></p>

        <post-files-carousel v-if="post.hasFilesCarousel" :post="post" @open-file="(fileIndex) => openImage(post, false, fileIndex)"></post-files-carousel>

        <video-player v-if="post.file && isVideoFile" :file="post.file"></video-player>

        <audio-player v-if="post.file && !isVideoFile && isAudioFile" :file="post.file"></audio-player>

        <div v-if="post.file && !isAudioFile && !isVideoFile" class="feed-post-image" @click="openImage(post)">
            <div class="image-mask" :class="{ 'overflow-visible': isDocument }">
                <u-thumbnail
                    :tinyIcon="true"
                    :file="post.file"
                    :isFeed="true"
                    :hasRadius="true"
                    :hasButton="true"
                    @openFile="openImage(post)"
                ></u-thumbnail>
            </div>
        </div>

        <u-text 
            v-if="post.fileId && !post.file"
            color="grey4"
            :class="['pl-7 pr-7', { 'mt-5': postDescription }]"
        >Arquivo excluído</u-text>

        <div @click="openForm(post)" class="post-form" v-if="post.postFormResponse">
            <i class="icon icon-form icon18 grey6 mb-4"></i>
            <p class="post-form-title"> Preencheu o formulário </p>
            <p class="post-form-name" v-html="post.postFormResponse.formName"></p>
        </div>

        <div v-if="post.comments && post.comments.length > 0" class="feed-post-comments">
            <div class="comment" 
                v-for="(comment, index) in post.comments" 
                :key="index"
            >
                <div class="comment-header no-align">
                    <div class="comment-user-logo">
                        <u-avatar
                            :size="5"
                            :user="comment.user"
                        ></u-avatar>
                    </div>

                    <div class="comment-info">
                        <div class="comment-info-header">
                            <p 
                                class="comment-user-name"
                                v-html="comment.user.name"
                            ></p>

                            <p 
                                class="comment-user-date"
                                v-html="postDate(comment.createdAt)"
                            ></p>
                        </div>

                        <div class="description-box">
                            <p 
                                class="comment-description"
                                v-html="getCommentDescription(comment)"
                            ></p>

                            <audio-player 
                                v-if="comment.file && (!isVideoCommentFile(comment.file) && isAudioCommentFile(comment.file))" 
                                :file="comment.file"
                            ></audio-player>

                            <div v-if="comment.file && !isAudioCommentFile(comment.file)" @click="openImage(comment, true)" style="cursor: pointer;">
                                <u-thumbnail
                                    :tinyIcon="true"
                                    :file="comment.file"
                                    :isDrive="true"         
                                    :hasRadius="true"
                                    :isCommentFeed="true"
                                    height="64px"
                                    width="64px"
                                ></u-thumbnail>
                            </div>

                            <div v-if="comment.hasFilesCarousel && comment.description.files">
                                <div class="d-flex align-center mt-2">
                                    <u-thumbnail
                                        v-for="(file, index) in comment.description.files.slice(0, 5)"
                                        :key="file.id"
                                        :tinyIcon="true"
                                        :file="file"
                                        :isDrive="true"         
                                        :hasRadius="true"
                                        :isCommentFeed="true"
                                        height="64px"
                                        width="64px"
                                        class="mr-2"
                                        style="overflow: hidden; border-radius: 1rem;"
                                        @click="openImage(comment, false, index)"
                                    ></u-thumbnail>

                                    <div v-if="comment.description.files.length > 5" class="post-files-carousel-grid-has-more">
                                        <u-text color="grey7">+{{ comment.description.files.length - 5 }}</u-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-see-more" v-if="showSeeMore">
                <p  
                    @click="showMoreComments(post.id)"
                    class="see-more"
                    v-html="'Ver mais ' + (post.commentsTotal - 2) + ' comentários'"
                ></p>

                <img v-if="loadingSeeMore" src="/images/loading-ummense.gif"/>
            </div>
        </div>

        <div v-if="canComment" class="new-comment">
            <comment-editor
                :post="post.id"
                :project="post.model"
                @updateNewComment="updateNewComment"
            ></comment-editor>
        </div>

        <modal-image
            v-if="currentImg && dialogModalImage"
            :dialog="dialogModalImage"
            :image="currentImg"
            :post="currentPost"
            :showSidebar="showSidebar"
            :hasComment="hasComment"
            @closeModal="dialogModalImage = false"
            @updateNewComment="updateNewComment"
            @setCurrentImg="setCurrentImg"
            @show-carousel-comment="(comment) => openImage(comment, false, 0)"
        ></modal-image>
        
        <modal-form
            v-if="currentRequest && dialogModalForm"
            :form="currentRequest"
            :dialog="dialogModalForm"
            @closeModal="dialogModalForm = false"
            @updateNewComment="updateNewComment"
        ></modal-form>

        <loading
            :active.sync="loadingModal"
            color="#208fcf"
            :width="100"
            :height="100"
        ></loading>
    </div>
</template>

<script>
    import { hasPreference, getFileFormat, audioPlayerAccepted, videoPlayerAccepted } from "@/assets/js/helpers/index.js";
    import {mapGetters, mapActions} from 'vuex'
    import moment from 'moment'
    import 'moment/locale/pt-br';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Loading from 'vue-loading-overlay';
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import UThumbnail from '@/assets/js/components/feed/UThumbnail'
    import CommentEditor from '@/assets/js/components/feed/CommentEditor'
    import VideoPlayer from '@/assets/js/components/UI/players/VideoPlayer'
    import AudioPlayer from '@/assets/js/components/UI/players/AudioPlayer'
    import ModalImage from './ModalImage'
    import ModalForm from './ModalForm'
    import PostFilesCarousel from './PostFilesCarousel'

    export default {
        props: { 
            post: {
                required: true,
                type: Object
            }
        },

        components: { 
            UAvatar,
            CommentEditor,
            UThumbnail,
            ModalImage,
            ModalForm,
            Loading,
            VideoPlayer,
            AudioPlayer,
            PostFilesCarousel,
        },
        
        data() { 
            return { 
                loadingSeeMore: false,
                loadingModal: false,
                currentImg: null,
                dialogModalImage: false,
                currentPost: [],
                currentRequest: [],
                dialogModalForm: false,
                seeActivated: false,
                checkSize: false,
                showSidebar: true
            }
        },

        created() { 
            this.$nextTick(() => {
                this.displayButton()
            })
        },

        computed: { 
            ...mapGetters("Auth", {
                preferences: "getPreferences",
                user: "getContact"
            }),

            postDescription() {
                if (this.post.hasFilesCarousel) {
                    return this.post.description.message ?? ''
                }

                return this.post.description
            },

            showSeeMore() { 
                if (this.post.commentsTotal <= this.post.comments.length) {
                    return false
                }

                if (this.post.commentsTotal > 2) {
                    return true
                }  
                
                return false
            },

            isActivated() { 
                if (this.seeActivated) { 
                    return "Ver menos"
                }

                return "Ver mais"
            },

            canComment() { 
                if (!this.post.id) { 
                    return false
                }

                return !this.hasPreference('user', 'canReplyPosts') ? true : this.preferences.user.canReplyPosts
            },

            hasComment() { 
                return this.postDescription != "" && this.postDescription != null || this.post.comments.length > 0
            },

            isDocument() {           
                return this.post.file && getFileFormat(this.post.file.type) === 'documents'
            },

            isVideoFile() {           
                return this.post.file && getFileFormat(this.post.file.type) === 'video' && videoPlayerAccepted(this.post.file.type)
            },

            isAudioFile() {           
                return this.post.file && getFileFormat(this.post.file.type) === 'audio' && audioPlayerAccepted(this.post.file.type)
            },

            isFilesCarousel() {
                return false
            },

            hasEmbed() { 
                var post = document.getElementById(`description-${this.post.id}`)
                
                if (!post) { 
                    return
                }

                var element = post.querySelector('iframe')
                return element
            },
        },  

        methods: {
            hasPreference,

            ...mapActions("Feed", [
                "getPostComments",
            ]),

            ...mapActions("Forms", [
                "getFormResponse"
            ]),

            displayButton() { 
                if ((this.checkSize && this.$isMobile()) || !!this.hasEmbed) return

                var growDiv = document.getElementById('description-' + this.post.id)
    
                if (growDiv && growDiv.clientHeight <= 315) {
                    this.checkSize = false
                } else if (growDiv && growDiv.clientHeight > 315) {
                    this.checkSize = true
                }
            },

            seeMoreDescription() { 
                this.seeActivated = !this.seeActivated
            },

            postDate(date) {
                return moment(date).fromNow()
            },

            updateNewComment(comment, isModal = false) {
                this.post.comments = this.post.comments.concat(comment)

                if (isModal) { 
                    this.modalComments = this.modalComments.concat(comment)
                    this.currentPost.comments = this.modalComments 
                    this.currentRequest.comments = this.modalComments
                }
            },

            showMoreComments(currentPost) { 
                this.loadingSeeMore = true

                this.getPostComments(currentPost).then((data) => {
                    this.post.comments = data
                    this.loadingSeeMore = false
                })
            },

            openImage(post, isComment, fileIndex = null) {
                if (!post.id) return
                
                let file = post.file
                
                if (fileIndex != null) {
                    file = post.description.files[fileIndex]
                }

                if (isComment) {
                    this.showSidebar = false
                    this.currentImg = file
                    this.dialogModalImage = true
                    this.currentPost = {
                        comments: [],
                        currentPost: post
                    }
                    return
                }

                 this.loadingModal = true

                this.getPostComments(post.id).then((data) => {
                    this.modalComments = data

                    let postInfo = {
                        comments: this.modalComments,
                        currentPost: post
                    }

                    this.currentImg = file
                    this.dialogModalImage = true
                    this.loadingModal = false
                    this.currentPost = postInfo
                    this.showSidebar = true
                })
            },

            openForm(post) {
                this.loadingModal = true

                this.getFormResponse(post.id).then((data) => {
                    this.modalComments = data.formResponse.comments

                    this.currentRequest = data.formResponse
                    this.currentRequest.comments = this.modalComments
                    
                    this.dialogModalForm = true
                    this.loadingModal = false
                })
            },

            replaceDescription(description) { 
                return description.replaceAll('{nome_do_contato}', this.user.name).replaceAll('{email_do_contato}', this.user.email)
            },

            isVideoCommentFile(file) {           
                return file && getFileFormat(file.type) === 'video' && videoPlayerAccepted(file.type)
            },

            isAudioCommentFile(file) {           
                return file && getFileFormat(file.type) === 'audio' && audioPlayerAccepted(file.type)
            },

            setCurrentImg(file) {
                this.currentImg = file
            },

            getCommentDescription(comment) {
                if (comment.hasFilesCarousel) {
                    return comment.description.message
                }

                return comment.description
            },
        },
    }
</script>

<style lang="scss" scoped>
    .post {
        width: 100%;
        padding-bottom: $spacing-7;
        background-color: $white-color;
        margin-bottom: $spacing-4;
        border-radius: 16px;
    }

    .feed-post-header,
    .comment-header { 
        padding: 24px 24px 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        &.no-align { 
            margin-bottom: 0;
            align-items: flex-start;
        }

        .feed-post-user-logo,
        .comment-user-logo { 
            margin-right: 12px;

            img { 
                height: 100%;
            }
        }

        .post-info,
        .comment-info {
            display: flex; 
            flex-direction: column;


            .comment-info-header { 
                display: flex;
                align-items: center;

                .comment-user-date  {
                    color: $grey4;
                    font-size: 12px;
                }
            }

            .post-user-name {
                color: $grey8;
                font-size: 16px;
                font-weight: 600;
            }
            
            .comment-user-name { 
                color: $grey8;
                font-size: 16px;
                font-weight: 600;
                margin-right: 8px;
            }

            .post-date,
            .comment-date,
            .post-project {
                color: $grey5;
                font-size: 12px;
            }

            .edited-tag {
                padding-top: $spacing-1;
                padding-bottom: $spacing-1;
                padding-right: $spacing-3;
                padding-left: $spacing-3;
                margin-left: $spacing-2;
                border-radius: 10rem;
                background-color: $grey1;
            }

            .box-info { 
                display: flex;
                align-items: center;

                svg { 
                    margin-left: 5px;
                }
            }
        }
    }

    .feed-post-description { 
        padding: 0 24px;
        font-size: 16px;
        color: $grey8;
        max-width: 100%;
        word-wrap: break-word;
        white-space: pre-wrap;
        overflow: hidden;

        &.fit { 
            display: block;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 15;
            -webkit-box-orient: vertical;
        }

        &.is-activated { 
            -webkit-line-clamp: unset;
        }

    }
    
    .see-more { 
        margin-top: 8px;
        cursor: pointer;
        font-size: 16px;
        color: $blue5;
        width: fit-content;
        -webkit-touch-callout: none;  
        -webkit-user-select: none;  
        -khtml-user-select: none;    
        -moz-user-select: none;     
        -ms-user-select: none;        
        user-select: none;      

        &.no-show { 
            display: none;
        }
    }
    
    .description-box { 
        margin-top: 8px;
        background-color: $grey1;
        max-width: 100%;
        width: fit-content;
        border-radius: 0px 16px 16px 16px;
        padding: 12px;

        .comment-description { 
            font-size: 16px;
            color: $grey8;
            max-width: 100%;
            word-wrap: break-word;
            overflow-wrap: anywhere;
        }
    }

    .box-see-more { 
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        height: 20px;

        img { 
            width: 20px;
        }
    }

    .see-more { 
        cursor: pointer;
        font-size: 16px;
        color: $blue5;
        padding-left: 24px;
        margin-right: 0.5rem;

        &:hover { 
            color: $blue3;
        }
    }

    .feed-post-image { 
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        margin: 12px 24px 0 24px;
        
        .image-mask { 
            display: flex;
            align-items: flex-start;
            border-radius: 1rem;
            width: 100%;
            max-height: 622px;
            overflow: hidden;

            &.overflow-visible { 
                overflow: visible;
            }
        }
    }

    .post-form { 
        cursor: pointer;
        background-color: $grey1;
        height: 140px;
        width: calc(100% - 48px);
        margin: 0 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .post-form-title { 
            margin-bottom: 12px;
            color: $grey5;
            font-size: 16px;
        }

        .post-form-name { 
            color: $blue5;
            font-size: 15px;
        }
    }

    .new-comment {
        margin-top: 15px;
        padding: 0 24px;
        display: flex;
        align-items: center;

        .avatar-comment { 
            width: 10%;
        }
    }
</style>