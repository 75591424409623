<template>
    <a href="" @click.prevent="$emit('click')" :class="['side-menu-item', { 'side-menu-item-active': active }]">
        <div>
            <u-text class="mb-1" :semibold="active || semibold">{{ text }}</u-text>
            <div v-if="ticket && showTicketOnCard" class="side-menu-item-ticket">
                <i class="icon icon-ticket icon8 grey4 mr-2"></i>
                <u-text size="4" color="grey4">{{ ticket }}</u-text>
            </div>
        </div>

        <i v-if="statusIconClass" :class="['icon icon12', statusIconClass, statusColorClass]"></i>
    </a>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        text: {
            type: String,
        },

        ticket: {
            type: String,
        },

        status: {
            type: String,
        },

        active: {
            type: Boolean,
            default: false,
        },

        semibold: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        ...mapState('Feed', ['showTicketOnCard']),

        statusIconClass() {
            if (this.status == 'completed') {
                return 'icon-check-circle-fill-1'
            }

            if (this.status == 'canceled') {
                return 'icon-cancel-circle-fill'
            }

            if (this.status == 'waiting') {
                return 'icon-pause-circle-filled'
            }


            return null
        },

        statusColorClass() {
            if (this.status == 'completed') {
                return 'green5'
            }

            if (this.status == 'canceled') {
                return 'grey4'
            }

            if (this.status == 'waiting') {
                return 'yellow4'
            }

            return null
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $grey7;
    padding: $spacing-4;
    margin-bottom: $spacing-1;
    border-radius: 4px;
    transition: all .2s;

    &:hover {
        background-color: $grey1;
    }

    &.side-menu-item-active {
        color: $blue5;
        background-color: $blue0;
    }

    .side-menu-item-ticket {
        display: flex;
        align-items: center;
    }
}

</style>