<template>
    <div class="post-files-carousel">
        <div v-if="files.length <= 4" :class="carouselClass">
            <u-thumbnail
                v-for="file in files"
                :key="file.id"
                :file="file"
                :radius="0.4"
                :isFeed="true"
                is-grid-post
                has-button
                iconSize="icon36"
                imgMaxHeight="none"
                class="post-files-carousel-item"
                @openFile="openFile(file)"
                @click="openFile(file)"
            ></u-thumbnail>
        </div>

        <div v-else class="post-files-carousel-grid-multiple">
            <div class="post-files-carousel-grid-multiple-first">
                <u-thumbnail
                    v-for="file in files.slice(0, 2)"
                    :key="file.id"
                    :file="file"
                    :radius="0.4"
                    :isFeed="true"
                    is-grid-post
                    has-button
                    iconSize="icon36"
                    imgMaxHeight="none"
                    class="post-files-carousel-item"
                    @open-file="openFile(file)"
                    @click="openFile(file)"
                ></u-thumbnail>
            </div>

            <div class="post-files-carousel-grid-multiple-last">
                <u-thumbnail
                    v-for="file in files.slice(2, 5)"
                    :key="file.id"
                    :file="file"
                    :radius="0.4"
                    :isFeed="true"
                    :isDrive="true"
                    is-grid-post
                    has-button
                    iconSize="icon36"
                    imgMaxHeight="none"
                    class="post-files-carousel-item"
                    @open-file="openFile(file)"
                    @click="openFile(file)"
                ></u-thumbnail>

                <div v-if="files.length > 5" class="post-files-carousel-grid-has-more">
                    <u-title size="3" color="white1">+{{ files.length - 4 }}</u-title>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UThumbnail from '@/assets/js/components/feed/UThumbnail'

export default {
    props: {
        post: {
            type: Object,
            required: true,
        }
    },

    components: {
        UThumbnail,
    },

    computed: {
        files() {
            return this.post.description.files
        },

        carouselClass() {
            const fileCount = this.files.length
            return fileCount <= 4 ? `post-files-carousel-grid-${fileCount}` : 'post-files-carousel-grid-multiple'
        }
    },

    methods: {
        openFile(currentFile) {
            const fileIndex = this.files.findIndex(file => file.id === currentFile.id)
            this.$emit('open-file', fileIndex)
        }
    }
}
</script>

<style lang="scss" scoped>

.post-files-carousel {
    padding: 0 24px;
    margin-top: 12px;
}

.post-files-carousel-item {
    max-height: 13.6rem;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.post-files-carousel-grid-2,
.post-files-carousel-grid-3,
.post-files-carousel-grid-4 {
    display: grid;
    gap: 4px;
}

.post-files-carousel-grid-2 {
    grid-template-columns: 1fr 1fr;
    .post-files-carousel-item {
        max-height: 28rem;
    }
}

.post-files-carousel-grid-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    .post-files-carousel-item:first-child {
        grid-row: span 2 / span 2;
        max-height: 27.4rem;
    }
}

.post-files-carousel-grid-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.post-files-carousel-grid-multiple-first {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 4px;
    margin-bottom: 4px;
}

.post-files-carousel-grid-multiple-last {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 4px;
    position: relative;
}

.post-files-carousel-grid-has-more {
    height: 100%;
    width: 32.6%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    background-color: rgba(16, 35, 51, 0.5);
}

</style>